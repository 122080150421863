<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="couponRequests"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.social_media_channel") }}</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.address") }}</th>
          <th>{{ $t("tables.info") }}</th>
          <th>{{ $t("tables.date") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <td class="budget">
            {{ row.item.social_media_channel }}
          </td>
          <td class="budget">
            {{ row.item.name }}
          </td>
          <td class="budget">
            {{ row.item.phone }}
          </td>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget wrap">
            {{ row.item.address }}
          </td>
          <td class="budget wrap">
            {{ row.item.tell_us }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "coupon-requests-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      validationErrors: [],
      couponRequests: [],
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.moment = moment;
    this.getCouponRequests("admin/administration/couponRequests/index");
  },
  watch: {
    page: function () {
      this.getCouponRequests(
        "admin/administration/couponRequests/index?page=" + this.page
      );
    },
  },
  methods: {
    getCouponRequests: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.couponRequests = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style scoped>
.wrap{
  white-space: initial;
  max-width: 400px;
  min-width: 300px;
}
</style>